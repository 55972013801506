<template>
  <div class="mt-4  px-5">

    <h2>Verbände</h2>

    <v-data-table
        :headers="headers"
        :items="comp_Verband"
        sort-by="nummer"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-spacer></v-spacer>
          <v-btn icon
                 @click="getVerband">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.beginn="{ item }">
        <span>{{ new Date(item.beginn).toLocaleString() }}</span>
      </template>
      <template v-slot:item.ende="{ item }">
        <span>{{ new Date(item.ende).toLocaleString() }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn class="info" text @click="editItem(item)">
          <v-icon class="mr-2">
            mdi-pencil
          </v-icon>
          Edit
        </v-btn>


      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Verband',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        align: 'Kurz',
        sortable: false,
      },
      {text: 'Kurz', value: 'Kurz'},
      {text: 'Name', value: 'Name'},
      {text: 'Ort', value: 'Ort'},
      {text: 'PLZ', value: 'PLZ'},
      {text: 'Strasse', value: 'Strasse'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],

    editedItem: [],
  }),
  computed: {
    comp_Verband() {
      return this.$store.state.AdminApp.Verband.VerbandAll;
    },
  },
  mounted() {
    this.getVerband();
  },
  methods: {

    getVerband() {
      this.$store.dispatch('AdminApp/getVerbandData');
    },
    editItem(item) {
      console.log(item);
    },
    initialize() {
      this.getVerband();
    },
  },

}
</script>

<style scoped>

</style>